import React, { useEffect, useState } from "react";
import Select from "./Select";
import Search from "./Search";
import FormModal from "./FormModal";
import TableSearch from "./TableSearch";
import { useSearchStore } from "../store/useSearchStore";
import { VscArrowBoth } from "react-icons/vsc";
import { useEvaluationStore } from "../store/useEvaluationStore";
import { useInconformidadStore } from "../store/useInconformidadStore";
import styles from "./styles/Aside.module.css";
import iconAmarillo from "../assets/images/note-edit-icon_amarillo.png";
import icon from "../assets/images/note-edit-icon.png";
import Alerta from "./Alert";

import { getRequisitos } from "../features/inconformidad/services/requisitosService";
import { fetchStatus } from "../features/secretaria/secretariaAdminSems/GenerarDocInconformidad/hooks/useFetchStatus";
import { useCargaHorariaStore } from "../store/useCargaHorariaStore";

const Aside = ({ selectVisible = false, alias }) => {
  const { findAndSetDataAcademico, setFullDataAcademico, setIsConcursante, status, setStatusEvaluacion } =
    useEvaluationStore();
  const {
    setFullDataAcademicoInconformidad,
    setIsConcursanteInconformidad,
    setErrorInconformidad,
    errorInconformidad,
    setStatusInconformidad,
    statusInconformidad,
  } = useInconformidadStore();
  const { fetchCargaGlobal } = useCargaHorariaStore();
  const { loading, hasSearched } = useSearchStore((state) => ({
    loading: state.loading,
    hasSearched: state.hasSearched,
  }));

  const clearSearch = useSearchStore((state) => state.clearSearch);
  const academicos = useSearchStore((state) => state.academicos);
  const [isSelected, setIsSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const roleUser = localStorage.getItem("rol");
  console.log(roleUser);

  useEffect(() => {
    if (!loading && hasSearched && academicos.length === 0) {
      const delayTimer = setTimeout(() => {
        setShowAlert(true);

        // Oculta despues de 5s
        const autoHideTimer = setTimeout(() => {
          setShowAlert(false);
        }, 5000);

        return () => clearTimeout(autoHideTimer);
      }, 1500); //espera 1.5 segundos para renderizar

      return () => clearTimeout(delayTimer);
    }
  }, [loading, academicos, hasSearched]);

  useEffect(() => {
    clearSearch();
    // eslint-disable-next-line
  }, []);

  console.log('cambio de prueba')

  const handleClickAcademico = async (id) => {
    console.log(roleUser);
    const dataAcademico = findAndSetDataAcademico(id, academicos);
    console.log("✔Status: ", status);
    console.log("✔Data Academico: ", dataAcademico);
    setIsSelected(dataAcademico.codigo);
    console.log(dataAcademico.codigo);
    fetchCargaGlobal(dataAcademico.codigo);
    // Validate Rol
    if (
      roleUser === "comision_ingreso_promocion_personal_academico_sems" ||
      roleUser === "comision_ingreso_promocion_personal_academico_cu_ep" ||
      roleUser === "comision_ingreso_promocion_personal_academico_h_cgu"
    ) {
      const response = await setFullDataAcademicoInconformidad(dataAcademico.codigo);
      const responseStatus = await fetchStatus(dataAcademico.id);
      setStatusInconformidad(responseStatus);
      if (!response) {
        console.warn("No se pudieron recuperar todos los datos del academico");
        // setErrorInconformidad('Error desde aside');
        console.log(errorInconformidad);
        return;
      }
      console.log("Response from Aside for Inconformidad ~🚀: ", response);
      const isConcursante = await getRequisitos(response.idSolicitud, "inconformidad");
      console.log("isConcursante: ", isConcursante.data);
      setIsConcursanteInconformidad(isConcursante.data.esConcursante);
      return;
    }
    //----------------
    if (
      roleUser === "secretaria_admin_sems" ||
      roleUser === "secretaria_admin_cu" ||
      roleUser === "secretaria_escuelas_preparatorias" ||
      roleUser === "comision_especial_dictaminadora_ag" ||
      roleUser === "comision_especial_dictaminadora_sems" ||
      roleUser === "comision_dictaminadora_cu_ep"
    ) {
      // solo aplica para Dictaminadora y secretaria
      const response = await setFullDataAcademico(dataAcademico.codigo);
      console.log("Response from Aside for Evaluation ~🚀: ", response);
      const responseConcursante = await getRequisitos(response.idSolicitud, "evaluacion");
      setIsConcursante(responseConcursante.data.esConcursante);
      const responseStatus = await fetchStatus(dataAcademico.id);
      setStatusEvaluacion(responseStatus);
      // console.log("isConcursante: ", isConcursante.data);
      // console.log("🚀 ~ handleClickAcademico ~ isConcursante.data.esConcursante:", isConcursante)
      // setIsConcursante(isConcursante.data.esConcursante);
    }
  };

  const handleButtonClick = () => setShowTable((prev) => !prev);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <aside className={styles.aside}>
      <div className={styles.filters}>
        {roleUser === "contralor_gral" ? (
          <div className={selectVisible ? styles.hiddenSelect : ""}>
            <Select placeholder={"Seleccionar"} disabled={showModal} aliasActividad={alias} />
          </div>
        ) : null}
        <div className={styles.filter_search}>
          <Search aliasActividad={alias} />
        </div>

        <button className={styles.pBusquedaAvanzada} onClick={handleShowModal}>
          Búsqueda avanzada
        </button>
        <button className={styles.arrowBoth} onClick={handleButtonClick}>
          <VscArrowBoth />
        </button>

        {showTable && (
          <>
            <div className={styles.backdrop} onClick={() => setShowTable(false)} />
            <TableSearch participantes={academicos} />
          </>
        )}
      </div>

      <div className={styles.containerList}>
        {showAlert && academicos.length === 0 && hasSearched ? (
          <Alerta typeAlert="error">
            <p>
              {
                "La búsqueda no arroja resultados, intente con otro filtro o haga una búsqueda general sin filtros primero"
              }
            </p>
          </Alerta>
        ) : (
          academicos?.map((academico) => (
            <div
              key={academico.codigo}
              className={
                isSelected === academico.codigo ? `${styles.isSelected} ${styles.item_list}` : `${styles.item_list}`
              }
              onClick={() => handleClickAcademico(academico.id)}
            >
              <p className={styles.itemParrafo}>
                <span>{academico.codigo}.</span> {academico.apellidoPaterno} {academico.apellidoMaterno}{" "}
                {academico.nombre} {academico.inconformidad ? <span style={{ color: "crimson" }}>[INC]</span> : ""}
                {academico.comentario && (
                  <td>{academico.revisado ? <img src={icon} alt="Icon" /> : <img src={iconAmarillo} alt="Icon" />}</td>
                )}
              </p>
              <p className={styles.parrafoSpan}>
                <span
                  className={
                    academico.inconformidad
                      ? academico.respuestaInconformidad
                        ? styles.eAtendido
                        : styles.eVacio
                      : academico.nivelRomano
                      ? styles.eEvaluado
                      : styles.eVacio
                  }
                >
                  {academico.nivelRomano || "-"}
                </span>
                <span className={styles.spanTipo}>[{academico.nombreParticipacion}]</span>
              </p>
            </div>
          ))
        )}
      </div>

      <FormModal show={showModal} onHide={handleCloseModal} aliasActividad={alias} />
    </aside>
  );
};

export default Aside;
