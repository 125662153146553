import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useSearchStore = create(
    persist(
        (set, get) => ({
            academicos: [],
            loading: false, 
            hasSearched: false,
            setAcademicos: (array) => set({ academicos: array, loading: false }),
            setLoading: (isLoading) => set({ loading: isLoading }),
            setHasSearched: (value) => set({ hasSearched: value }),
            clearSearch: () => set({ academicos: [], loading: false, hasSearched: false }),
        }),
        { name: 'search-storage' }, // Optional: name for persistence  
    )
);
