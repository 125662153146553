import React, { useEffect } from "react";
import "./styles.css";
import { Navigation } from "./router/Navigation";


import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "./features/authService";

function App() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_FRONT_VERSION = `${API_BASE_URL}/public/version`;
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);

  const getVersion = async () => {
    try {
      const version = await axios.get(API_FRONT_VERSION + `?version=${process.env.REACT_APP_FRONT_VERSION}`);
      console.log(version);
    } catch (error) {
      alert(`Estimado usuario, no cuenta con la última versión del sistema.

Limpie la caché de su navegador o ingrese en modo incognito para asegurar que cuenta con los últimos cambios liberados.
`);
      localStorage.clear();
      navigate("/login", { replace: true });
    }
  };
  useEffect(() => {
    if (location.pathname === "/login") return;
    getVersion();
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    const handleStorageChange = () => {
      const localSessionTimestamp = localStorage.getItem('sessionTimestamp');
      const currentSessionTimestamp = sessionStorage.getItem('sessionTimestamp');

      // Si el timestamp local es más reciente, cerrar la sesión actual
      if (new Date(localSessionTimestamp) > new Date(currentSessionTimestamp)) {
        console.log('Una nueva sesión ha sido iniciada. Cerrando esta sesión.');
        navigate('/login', { replace: true });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line
  }, []);
  return <Navigation />;
}

export default App;
