import React, { useEffect, useState } from "react";
import axios from "axios";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import styles from "./styles/Actas.module.css";
import { useForm } from "react-hook-form";
import Modal from "../../../reutilizable/Modal";
import ViewerPDF from "./ViewerPDF";
import Alert from "../../../reutilizable/Alert";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VISUALIZAR = `${API_BASE_URL}/api/v1/expediente/visualizar-acta`;
const API_ETAPA_DOCUMENTOS = `${API_BASE_URL}/api/v1/expediente/obtener-etapa-documentos`;

export const Actas = () => {
  const { selectedDataAcademico, isLoading } = useEvaluationStore();
  const { handleSubmit, register, watch } = useForm();
  const [documentos, setDocumentos] = useState([]);
  const [sigla, setSigla] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [loadingArchivo, setLoadingArchivo] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  const [error, setError] = useState(null);

  //cargar catalogo de documentos
  useEffect(() => {
    if (!selectedDataAcademico) return;
    setLoadingData(true);
    setError(null);
    const token = localStorage.getItem("accessToken");

    axios
      .get(API_ETAPA_DOCUMENTOS + `?idSolicitud=${selectedDataAcademico.id}`, {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const filteredArray = response.data.filter((item) => !(item.id === 6 && item.sigla === "F"));
        console.log("Documentos: ", filteredArray);
        setDocumentos(filteredArray);
      })
      .catch((error) => console.error("Error fetching grados: ", error))
      .finally(() => setLoadingData(false));
  }, [selectedDataAcademico]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(""); // Limpiar el mensaje de error después de 3 segundos
      }, 3000);
      return () => clearTimeout(timer); // Limpiar el timeout si el componente se desmonta
    }
  }, [error]);

  //buscar actas
  const handleSubmitEvaluacion = async (nodo) => {
    setLoadingArchivo(nodo);
    console.log("buscar actas", nodo);
    const token = localStorage.getItem("accessToken");

    axios
      .get(`${API_VISUALIZAR}?nodo=${nodo}`, {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      })
      .then((response) => {
        openModal();
        setUrlPdf(response.data); // Ensure you're passing the response.data which is the actual blob
      })
      .catch((error) => {
        setError({
          message: "Acta no encontrada",
          type: "error",
        });
        console.error("Error fetching: ", error);
      })
      .finally(() => setLoadingArchivo(null));
  };

  useEffect(() => {
    console.log(sigla);
    if (sigla === "" || sigla.length === 0) return;
    setListaDocumentos(documentos.find((documento) => documento.sigla === sigla));
    console.log(documentos.find((documento) => documento.sigla === sigla));
  }, [sigla, documentos]);

  if (loadingData) return <p style={{textAlign: 'center'}}>Cargando...</p>;

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal} width="850px">
        <ViewerPDF urlPdf={urlPdf} />
      </Modal>
      <div>
        <div className={styles.restdiv}>
          <div className={styles.containerInputsSelectRadio}>
            <div className={styles.inputSelect}>
              <select
                name="documento"
                id="documento"
                {...register("documento")}
                onChange={(e) => setSigla(e.target.value)}
              >
                <option value="" disabled selected={true}>
                  -- Seleccione su nombre de la lista --
                </option>
                {documentos.map((documento) => (
                  <option key={documento.sigla} value={documento.sigla}>
                    {documento.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            {listaDocumentos?.id && (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {listaDocumentos?.archivos?.map((archivo) => (
                    <tr key={archivo.id}>
                      <td>{archivo.nombre}</td>
                      <td>
                        <button
                          onClick={() => handleSubmitEvaluacion(archivo.nodo)}
                          className={
                            loadingArchivo === archivo.nodo ? styles.buttonVisualizarDisabled : styles.buttonVisualizar
                          }
                          disabled={loadingArchivo === archivo.nodo}
                        >
                          Visualizar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {error ? (
        <Alert typeAlert={error.type}>
          <p>{error.message}</p>
        </Alert>
      ) : (
        ""
      )}
    </>
  );
};
