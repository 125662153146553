import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Alert from "../../../reutilizable/Alert";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import {
  enviarComentariosCotejo,
  obtenerStatusCotejo,
  agregrarComentarioCotejo,
  obtenerComentariosCotejo,
} from "../services/comentariosCotejo";
import { ERROR_MESSAGES_GENERICS_API } from "../../../utils/messagesFromAPI";
import styles from "./styles/ComentariosCotejo.module.css";
import Loading from "../../../reutilizable/Loading";
import useCotejoStore from "../../../store/useCotejoStore";
import Modal from "../../../reutilizable/Modal";

const ComentariosCotejo = () => {
  const { requisitos, rubros, resetStore } = useCotejoStore();
  const { selectedDataAcademico, idSolicitud } = useEvaluationStore();
  const [comentarioEnviado, setComentarioEnviado] = useState(false);
  const [comentarios, setComentarios] = useState([""]);
  const SIN_COTEJAR = 0;
  const COTEJADA_CON_CORRECCION = 2;
  const [statusCotejo, setStatusCotejo] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState({ type: null, mensaje: null });
  const [mensajeAlerta, setMensajeAlerta] = useState({ type: null, mensaje: null });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    obtenerComentariosCotejo(idSolicitud)
      .then((response) => {
        if (response.status === 200) setComentarios(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener comentarios de cotejo: ", error);
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setMensajeAlerta({
            type: "error",
            mensaje: message,
          });
        }
      });
  }, [idSolicitud, comentarioEnviado]);

  useEffect(() => {
    getStatus();
    // eslint-disable-next-line
  }, [idSolicitud]);

  const getStatus = async () => {
    obtenerStatusCotejo(idSolicitud)
      .then((response) => {
        if (response.status === 200) setStatusCotejo(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener status de cotejo: ", error);
      });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comentario: "",
    },
  });

  const handleEnviarCorreo = async () => {
    const body = { idSolicitud };

    setLoading(true);
    await enviarComentariosCotejo(body)
      .then((response) => {
        if (response.status === 200) {
          setIsVisible(!isVisible);
          setMensajeAlerta({
            type: "success",
            mensaje: "Correo enviado correctamente",
          });
        }
        resetStore();
        reset();
        getStatus();
        closeModal()
      })
      .catch((error) => {
        console.error("Error al enviar correo de cotejo: ", error);
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setErrorSubmit({
            type: "error",
            mensaje: message,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitComentario = async (data) => {
    const { comentario } = data;
    const body = { idSolicitud, comentario };
    await agregrarComentarioCotejo(body)
      .then((response) => {
        if (response.status === 200) {
          setIsVisible(!isVisible);
          setComentarioEnviado(!comentarioEnviado);
          setMensajeAlerta({
            type: "success",
            mensaje: "Comentario agregado correctamente",
          });
          reset();
        }
      })
      .catch((error) => {
        console.error("Error al agregar comentario: ", error);
        if (error.response) {
          setIsVisible(!isVisible);
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setErrorSubmit({
            type: "error",
            mensaje: message,
          });
        }
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isVisible]);

  const VALIDATION = {
    comentario: {
      required: "Debe escribir un comentario",
      minLength: { value: 5, message: "El mensaje debe tener al menos 5 caracteres" },
      maxLength: { value: 500, message: "El mensaje no puede superar los 500 caracteres" }, 
    },
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal} withFooter={false} title="ENVIAR COTEJO" width="400px">
        <p>Esta accion no puede deshacerse, por favor verifique detalladamente antes de enviar a cotejo</p>
        <br></br>
        <div className={styles.modalDiv}>
          <button type="button" onClick={handleEnviarCorreo} className={styles.btnEnviarCotejo}>
            Enviar Cotejo
            {loading && <Loading />}
          </button>
        </div>
      </Modal>

      <div className={styles.containerNotas}>
        <p className={styles.tituloNotas}>
          <FontAwesomeIcon icon={faAngleRight} color={"yellow"} size="xl" /> {""}
          {`Enviar cotejo del participante: 
          ${selectedDataAcademico.nombre} ${selectedDataAcademico.apellidoPaterno} ${selectedDataAcademico.apellidoMaterno}
        `}
        </p>

        {!comentarios.length <= 0 ? (
          <div className={styles.containerEntradas}>
            {comentarios?.map((comentario) => (
              <div key={comentario.id} className={styles.containerEntradaComentario}>
                <p>
                  <span>
                    [{comentario.fechaFormato} - {comentario.usuario}]
                  </span>
                  <p className={styles.spliText}>{comentario.comentario}</p>
                </p>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}

        <form onSubmit={handleSubmit(handleSubmitComentario)}>
          <div className={styles.containerAgregarEntrada}>
            <div className={styles.containerTextareaEntrada}>
              <p>Agregar comentarios de cotejo</p>
              <textarea
                title="Maximo 500 caracteres"
                rows={6}
                maxLength="499"
                className={errors.comentario ?? `${styles.containerTextareaEntradaError} : ''`}
                {...register("comentario", VALIDATION.comentario)}
                disabled={statusCotejo === COTEJADA_CON_CORRECCION}
              ></textarea>
            </div>

            {errors.comentario && (
              <Alert typeAlert={"error"}>
                <p>{errors.comentario.message}</p>
              </Alert>
            )}

            {isVisible && (
              <Alert typeAlert={mensajeAlerta.type}>
                <p>{mensajeAlerta.mensaje}</p>
              </Alert>
            )}

            {errorSubmit && isVisible && (
              <Alert typeAlert={errorSubmit.type}>
                <p>{errorSubmit.mensaje}</p>
              </Alert>
            )}

            {statusCotejo === COTEJADA_CON_CORRECCION && (
              <Alert typeAlert="warning">
                <p>{"Esta solicitud ya ha sido cotejada y no se permiten enviar comentarios"}</p>
              </Alert>
            )}

            <div className={styles.buttonsTextareaEntrada}>
              <button
                type="submit"
                className={styles.btnAgregarComentario}
                disabled={loading || statusCotejo === COTEJADA_CON_CORRECCION}
              >
                Agregar Comentario
              </button>
              <button type="button" onClick={openModal} className={styles.btnEnviarCotejo} disabled={loading || statusCotejo !== SIN_COTEJAR}>
                Enviar Cotejo
                {loading && <Loading />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ComentariosCotejo;
