import Alert from "../../../reutilizable/Alert";
import { ContainerLayout } from "../../layout/ContainerLayout";
import { FormCodeAcademic } from "../components/FormCodeAcademic";
import { MessageWarning } from "../components/MessageWarning";
import { TitlePage } from "../components/TitlePage";
import { useSuperPase } from "./hook/useSuperPase";
import styles from "./styles/SuperPase.module.css";

export const SuperPase = () => {
    const {
        academico,
        codigo,
        error,
        handleFormSubmit,
        handleSubmitCodigo,
        isVisible,
        loading,
        onChange,
        superPase
    } = useSuperPase();

    return (<div className={styles.container}>
        <TitlePage title='Super pase' />
        <ContainerLayout>
            <FormCodeAcademic
                actionForm={handleSubmitCodigo}
                changeCode={onChange}
                value={codigo}
                loading={loading}
                message={error}
                error={error.length > 0}
            />

            <div className={styles.form}>
                {academico ? (
                    <>
                        <h3>
                            <strong className={styles.textAlert}>
                                Cambie el valor del "superpase" y después asegurese de oprimir el botón "Guardar"
                            </strong>
                        </h3>
                        <form className={styles.container} onSubmit={handleFormSubmit}>
                            <div>
                                <span className={styles.subTiles}>Datos personales</span>
                                <div>
                                    <p className={styles.dataParticipante}>{academico?.nombre} {academico?.apellidoPaterno} {academico?.apellidoMaterno} {academico?.codigo}</p>
                                </div>
                            </div>

                            <div>
                                <span className={styles.subTiles}>Super pase</span>
                                <div className={styles.fechaInput}>
                                    <label htmlFor="superPaseSi">Tiene super pase: </label>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems:'start', gap: "10px" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap:"8px" }}>
                                            <label htmlFor="superPaseSi">Sí</label>
                                            <input
                                                type="radio"
                                                name="superPaseSi"
                                                id="superPaseSi"
                                                value="true"
                                                checked={superPase}
                                                onChange={onChange}
                                            />

                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
                                            <label htmlFor="superPaseNo">No</label>
                                            <input
                                                type="radio"
                                                name="superPaseNo"
                                                id="superPaseNo"
                                                value="false"
                                                checked={!superPase}
                                                onChange={onChange}
                                            />

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={styles.buttonForm}>
                                <button type="submit" className={styles.button}>Guardar</button>
                            </div>

                        </form>
                    </>
                ) : (<MessageWarning>Introduzca el código del académico y oprima el botón "Continuar".</MessageWarning>)}

                {isVisible && (
                    <Alert typeAlert={error.length > 0 ? "error" : "success"}>
                        {error.length > 0 ? error : 'Se ha guardo con éxito'}
                    </Alert>
                )}
            </div>


        </ContainerLayout>
    </div>
    );
};