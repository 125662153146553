import axios from "axios";
import { get, set } from "react-hook-form";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import api from "../hooks/api";

export const useCargaHorariaStore = create(persist((set, get) => ({
    cargaHorariaData: null,
    isLoadingCargaHoraria: true,
    isErrorCargaHoraria: null,
    total: 0,
    fetchCargaGlobal: async (code) => {
        try {
            set({ isErrorCargaHoraria: null });
            let total;
            const response = await api.get(`api/v1/solicitud/obtener-carga-global/${code}`);
            // console.warn('Carga Horaria Resonse: ', response);
            const cargaGlobal = response.data.CARGA_GLOBAL.map((item) => ({
                ...item,
                CARGA_HORARIA: Math.round(item.CARGA_HORARIA * 20),
            }));
            const totalSuma = cargaGlobal.reduce((acc, item) => acc + item.CARGA_HORARIA, 0);
            set({ cargaHorariaData: cargaGlobal, total: totalSuma });
        } catch (error) {
            console.error(error);
            set({ isErrorCargaHoraria: 'Hubo un error al tratar de obtener la carga horaria.' });
        } finally {
            set({ isLoadingCargaHoraria: false });
        }
    },
}),
{ name: 'carga-horaria' },
));