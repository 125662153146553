import React, { useEffect, useState } from "react";
import { usePublicacionFinal } from "./publicacion-final/hooks/usePublicacionFinal";
import styles from "./styles/styles.module.css";
import Loading from "../../../reutilizable/Loading";
import ConfirmationModal from "../components/ConfirmationModal";
import api from "../../../hooks/api";

function PublicacionResultados() {
  const { fetchPublicacionFinal, isLoading, error, fetchComisiones, setError } =
    usePublicacionFinal();
  const [comisiones, setComisiones] = useState([]);
  const [tipoComision, setTipoComision] = useState("comisionEspecial");
  const [selectedComision, setSelectedComision] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchComisiones().then((data) => {
      console.log(data);
      setComisiones(data);
    });
  }, [fetchComisiones]);

  const handleChangeComision = (e) => {
    console.log(e.target.value);
    setSelectedComision(e.target.value);
    setError(null)
  };

  // const handleSelectTypeComision = (e) => {
  //   console.log(e.target.checked);
  //   setTipoComision(e.target.checked ? "comisionIngreso" : "comisionEspecial");
  // };

  const handleSave = async () => {
    console.log("Guardar");
    await fetchPublicacionFinal(selectedComision);
  };

  const handleConfirm = async () => {
    console.log("confirmar");
    try {
      const response = await api.get('/api/v1/gaceta/descargar');
      console.log(response);
    } catch (error) {
      console.log(error);
      if(error.status === 422) return alert(error.response.data);
      alert('Surgio un error al intentar Generar la Publicación Final')
      return;
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (isLoading) return <Loading />;

  console.log(comisiones);

  return (
    <div className={styles.spacing}>
      <h2 className={styles.title}>Publicación de los resultados</h2>
      {comisiones ? (
        <>
          <div className={styles.containerMain}>
            <div className={styles.containerSelectComision}>
              <div className={styles.deIngreso}>
                <h2>Vista Previa de la Gaceta</h2>
              </div>

              <select
                name="comisiones"
                id="comisiones"
                value={selectedComision}
                onChange={handleChangeComision}
              >
                <option value="" disabled>
                  Seleccione una comisión
                </option>
                {tipoComision === "comisionEspecial" &&
                comisiones.comisionEspecial?.length > 0 ? (
                  comisiones.comisionEspecial.map((comision) => (
                    <option key={comision.id} value={comision.id}>
                      {comision.siglas}
                    </option>
                  ))
                ) : tipoComision === "comisionIngreso" &&
                  comisiones.comisionIngreso?.length > 0 ? (
                  comisiones.comisionIngreso.map((comision) => (
                    <option key={comision.id} value={comision.id}>
                      {comision.siglas}
                    </option>
                  ))
                ) : (
                  <option value="">No hay comisiones</option>
                )}
              </select>

              {/* Contenedor para el botón */}
              <div className={styles.buttonContainer}>
                <button className={styles.saveButton} onClick={handleSave}>
                  Generar comision final
                </button>
              </div>

              {error && <p className={styles.error}>No hay informaci&oacute;n disponible.</p>}
            </div>

            <div className={styles.containerSelectComision}>
              <h2>Envíar Publicación Final</h2>
              <button className={styles.saveButton} onClick={handleOpenModal}>
                Generar Publicación Final
              </button>
            </div>
          </div>
        </>
      ) : (
        <p>No hay comisiones</p>
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={"¿Estas seguro de que desea generar la Publicación Final?"}
      />
    </div>
  );
}

export default PublicacionResultados;
