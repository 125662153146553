import React, { useEffect, useState } from "react";
import styles from "./styles/DicFinal.module.css";
import { generatePDFDicFinal, uploadDictamenFinal } from "../services/dictamenService";
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Modal from "../../../reutilizable/Modal";
import ViewerPDF from "../../../reutilizable/ViewerPDF";
import { useDacFinal } from "../hooks/useDacFinal";

const DicFinal = () => {
    const {
        closeModal,
        handleFileChange,
        handleFileUpload,
        handleGenerateDictamen,
        handleSetUrlPdf,
        openModal,
        actaFile,
        actaNodoId,
        dictamenFile,
        isActaUploaded,
        isDictamenUploaded,
        isLoadingDictamen,
        isLoadingActa,
        isLoading,
        isModalOpen,
        urlPdf
    } = useDacFinal();
    const { statusEvaluacion, fetchStatusEvaluacion, } = useEvaluationStore();

    useEffect(() => {
        fetchStatusEvaluacion();
        // eslint-disable-next-line
    }, [])

    return (
        <div className={styles.container}>
            <Modal isOpen={isModalOpen} onClose={closeModal} width='850px'>
                <ViewerPDF urlPdf={urlPdf} />
            </Modal>

            <button
                className={styles.generateButton}
                onClick={handleGenerateDictamen}
                disabled={isLoading || statusEvaluacion === 7}
            >
                GENERAR DICTAMEN FINAL
            </button>

            { statusEvaluacion === 9 ? <div className={styles.uploadSection}>
                <h2>Subir documentos</h2>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Dictamen Firmado y Tabla</label>
                    {isDictamenUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => handleSetUrlPdf("dictamen")}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Dictamen ya subido</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="dictamenInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "dictamen")}
                                accept=".pdf,.docx"
                                disabled={isLoadingDictamen}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("dictamen")}
                                disabled={!dictamenFile || isLoadingDictamen}
                            >
                                {isLoadingDictamen ? "Subiendo..." : "Subir"}
                            </button>
                        </>
                    )}
                </div>


            </div>
            :
            <></>
            }
        </div>
    );
};

export default DicFinal;
