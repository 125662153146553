import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "../../../reutilizable/Alert";
import Loading from "../../../reutilizable/Loading";
import { useFormCriterios } from "../hooks/useFormCriterios";
import ProcesarCriterios from "./ProcesarCriterios";
import styles from "./styles/FormCriterios.module.css";
import TablaResultadosEvaluacion from "./TablaResultadosEvaluacion";
import { Spin } from "antd";
import { useEvaluationStore } from "../../../store/useEvaluationStore";

const FormCriterios = () => {
  const [codigoUsuario, setCodigoUsuario] = useState(null);
  const { fetchStatusEvaluacion, statusEvaluacion,} = useEvaluationStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [criteriosDedicacion, setCriteriosDedicacion] = useState([]);

  const username = localStorage.getItem("userName" || "");

  useEffect(() => {
    if (username) setCodigoUsuario(username);
  }, [username]);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const { miembros, arbolCriterios, resultadosEvaluacion, nivel, puntaje, isLoading, mensaje, onSubmitEvaluar } =
  useFormCriterios();

  // Preselect the member when codigoUsuario is available
  const currentUser = miembros?.find((miembro) => miembro.codigo.toString() === codigoUsuario);
  useEffect(() => {
    if (codigoUsuario) {
      setValue("idMiembro", currentUser?.id);
    }
  }, [currentUser, setValue, codigoUsuario]);

  useEffect(() => {
    if(arbolCriterios){

      const dedicacion = arbolCriterios[1].subCriterios;

      const criterios = [];
      for (const item of Object.values(dedicacion)) {
        criterios.push(item.criterio.id);
      }
      setCriteriosDedicacion(criterios);
    }
  }, [arbolCriterios]);

  const handleFormSubmit = async (data) => {
    // const criterios = [];
    // for (const item of Object.values(criteriosDedicacion)) {
    //   criterios.push(item.criterio.id);
    // }

    console.log("Criterios: ",criteriosDedicacion);
    console.log("Data: ",data);
    
    const hasEmptyValues = Object.values(data).some(
      (value) => value === ""
    );
  
    if (hasEmptyValues) {
      alert("No puede dejar un puntaje vacio"); // Show alert
      reset({ idMiembro: selectedMiembro });
      return; 
    }

    const isCriterioValid = criteriosDedicacion.some(id => data[id] === "1");

    if (!isCriterioValid) {
      alert("Debe seleccionar un criterio de dedicación");
      return;
    }

    setIsSubmitting(true); 
    await onSubmitEvaluar(data);
    fetchStatusEvaluacion();
    reset({ idMiembro: selectedMiembro });
    setTimeout(() => {
      setIsSubmitting(false); 
    }, 2000); 
  };

  if (isLoading) {
    return <Loading />;
  }

  const selectedMiembro = watch("idMiembro");

  // console.log("Lista de miembros: ", miembros);
  // console.log("Arbol criterios: ", arbolCriterios);
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {mensaje && (
        <Alert typeAlert={mensaje.type}>
          <p>{mensaje.mensaje}</p>
        </Alert>
      )}
      <div className={styles.formEvaluacion}>
        <ProcesarCriterios
          criterios={arbolCriterios}
          puntaje={puntaje}
          register={register}
          watch={watch}
          setValue={setValue}
          pdfDraw={true}
          typeRadio={0}
          criteriosDedicacion={criteriosDedicacion}
        />
      </div>

      {/* Sección del resto del formulario */}
      <div className={styles.restForm}>
        {console.log("codigoUsuario ", codigoUsuario)}
        <div className={styles.containerInputsSelectRadio}>
          <div className={styles.inputSelect}>
            <select
              disabled
              name="miembro"
              id="miembro"
              {...register("idMiembro", {
                required: {
                  value: true,
                  message: "Seleccione un miembro",
                },
              })}
              value={selectedMiembro || ""}
              onChange={(e) => setValue("idMiembro", e.target.value)} // Actualizamos el id seleccionado
            >
              <option value="" disabled>
                -- Seleccione el nombre de la lista --
              </option>
              {miembros &&
                miembros.map((miembro) => (
                  <option key={miembro.id} value={miembro.id}>
                    {miembro.nombre}
                  </option>
                ))}
            </select>
            {errors.idMiembro && <Alert typeAlert="error">{errors.idMiembro.message}</Alert>}
          </div>
        </div>

        <div className={styles.submit}>
          <button type="submit" 
            disabled={isSubmitting || isLoading || statusEvaluacion === 9} 
            className={statusEvaluacion === 9 ? styles.btnDisabled : ""}>
            {isLoading && <Spin style={{ marginRight: "10px" }} />}
            Evaluar
          </button>
          <TablaResultadosEvaluacion resultados={resultadosEvaluacion} nivel={nivel} />
        </div>
      </div>
    </form>
  );
};

export default FormCriterios;
