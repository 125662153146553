import React, { useState } from "react";
import { useSearchStore } from "../../../store/useSearchStore";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import styles from "./styles/AsideSecretaria.module.css";
import Search from "../../../reutilizable/Search";
import TableSearch from "../../../reutilizable/TableSearch";
import { VscArrowBoth } from "react-icons/vsc";
import iconAmarillo from "../../../assets/images/note-edit-icon_amarillo.png";
import icon from "../../../assets/images/note-edit-icon.png";
import FormModal from "../../../reutilizable/FormModal";
import { useEffect } from "react";
import { useCargaHorariaStore } from "../../../store/useCargaHorariaStore";

const AsideSecretaria = ({ alias }) => {
  const { selectedDataAcademico, findAndSetDataAcademico } = useEvaluationStore();
  const { fetchCargaGlobal } = useCargaHorariaStore();
  const academicos = useSearchStore((state) => state.academicos);
  const clearSearch = useSearchStore((state) => state.clearSearch);
  const hasSearched = useSearchStore((state) => state.hasSearched);
  const loading = useSearchStore((state) => state.loading);
  const [isSelected, setIsSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    console.log(hasSearched);
  }, [hasSearched]);

  const handleClickAcademico = (id) => {
    console.log("selecciono academico");
    const dataAcademico = findAndSetDataAcademico(id, academicos);
    setIsSelected(dataAcademico.codigo);
    fetchCargaGlobal(dataAcademico.codigo);
  };

  const handleButtonClick = () => setShowTable((prev) => !prev);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  //console.log(" academicos", academicos )

  useEffect(() => {
    clearSearch();
    // eslint-disable-next-line
  }, []);

  return (
    <aside className={styles.aside}>
      <div className={styles.filters}>
        <div className={styles.filter_search}>
          <Search aliasActividad={alias} />
        </div>

        <button className={styles.pBusquedaAvanzada} onClick={handleShowModal}>
          Búsqueda avanzada
        </button>
        <button className={styles.arrowBoth} onClick={handleButtonClick}>
          <VscArrowBoth />
        </button>

        {showTable && (
          <>
            <div className={styles.backdrop} onClick={() => setShowTable(false)} />
            <TableSearch participantes={academicos} />
          </>
        )}
      </div>

      <div className={styles.containerList}>
        {academicos && academicos.length === 0 && hasSearched && !loading ? (
          <div>No hay participantes registrados que coincidan con los criterios de búsqueda.</div>
        ) : (
          academicos?.map((academico) => (
            <div
              key={academico.codigo}
              className={
                isSelected === academico.codigo ? `${styles.isSelected} ${styles.item_list}` : `${styles.item_list}`
              }
              onClick={() => handleClickAcademico(academico.id)}
            >
              <p className={styles.itemParrafo}>
                <span>{academico.codigo}.</span> {academico.apellidoPaterno} {academico.apellidoMaterno}{" "}
                {academico.nombre} {academico.inconformidad ? <span style={{ color: "crimson" }}>[INC]</span> : ""}
                {academico.comentario && (
                  <td>{academico.revisado ? <img src={icon} alt="Icon" /> : <img src={iconAmarillo} alt="Icon" />}</td>
                )}
              </p>
              <p className={styles.parrafoSpan}>
                <span
                  className={
                    academico.inconformidad
                      ? academico.respuestaInconformidad
                        ? styles.eAtendido
                        : styles.eVacio
                      : academico.nivelRomano
                      ? styles.eEvaluado
                      : styles.eVacio
                  }
                >
                  {academico.nivelRomano || "-"}
                </span>
                <span className={styles.spanTipo}>[{academico.nombreParticipacion}]</span>
              </p>
            </div>
          ))
        )}
      </div>

      <FormModal show={showModal} onHide={handleCloseModal} aliasActividad={alias} />
    </aside>
  );
};

export default AsideSecretaria;
