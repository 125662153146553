import React, { useState } from "react";
import styles from "./TablaCargaHoraria.module.css";
import { useCargaHorariaStore } from "../../store/useCargaHorariaStore";
import Loading from "../Loading";

function TablaCargaHoraria() {
  const { cargaHorariaData, isLoadingCargaHoraria, isErrorCargaHoraria, total } = useCargaHorariaStore();
  const rol = localStorage.getItem("rol");

  // if( cargaHorariaData.CARGA_GLOBAL )

  if (!cargaHorariaData) return <p>No hay informacion sobre la carga horaria global.</p>;
  if (isLoadingCargaHoraria) return <Loading />;
  if (isErrorCargaHoraria) return <p>{isErrorCargaHoraria}</p>;

  const prioridadNiveles = {
    TÉCNICO: 1,
    BACHILLERATO: 2,
    LICENCIATURA: 3,
    MAESTRÍA: 4,
    DOCTORADO: 5,
  };

  const cargaHorariaOrdenada = [...cargaHorariaData].sort((a, b) => {
    const nivelA = prioridadNiveles[a.NIVEL.toUpperCase()] || 999; // Si no está en la lista, lo manda al final
    const nivelB = prioridadNiveles[b.NIVEL.toUpperCase()] || 999;
    return nivelA - nivelB;
  });

  return (
    <>
      <div className={styles.tituloContainer}>
        <h2 className={styles.titulo}>Carga Global</h2>
        <h2 className={styles.textBlack}>(Ciclo escolar actual)</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th className={styles.tableHeader}>Nivel</th>
            <th className={styles.tableHeader}>Carga Horaria</th>
          </tr>
        </thead>
        <tbody>
          {!isLoadingCargaHoraria ? (
            cargaHorariaOrdenada.map((item, index) => (
              <tr key={index}>
                <td>{item.NIVEL}</td>
                <td>{item.CARGA_HORARIA}</td>
              </tr>
            ))
          ) : (
            <p>Hubo un error al cargar los datos</p>
          )}
          <tr className={styles.totalRow}>
            <td>TOTAL</td>
            <td>{total}</td>
          </tr>
        </tbody>
      </table>

      {rol === "academico" ? (
        <>
          <h1 style={{ marginTop: "20px", color: "red" }}>
            <strong>IMPORTANTE</strong>
          </h1>
          <ul>
            {/* <li>La solicitud y la carta compromiso se genera automáticamente al hacerse el registro en línea.</li>
                                        <li>Si los datos contienen errores una vez impresos, puede acceder nuevamente al sistema y modificarlos.</li>
                                        <li>El sistema generará los documentos con los datos actualizados al finalizar las modificaciones.</li> */}
            <li>
              <strong>El sistema puede demorar algunos segundos en responder.</strong>
            </li>
          </ul>

          <ul className={styles.important}>
            <li>Los datos que se muestran precargados en este formulario son extraídos directamente desde SIA-RH.</li>
            <li>
              En caso de que requiera modificar alguno de estos datos, favor de acudir a la Coordinación de Personal de
              su dependencia de adscripción para la actualización correspondiente.
            </li>
          </ul>
        </>
      ) : (
        <>
          <p className={styles.important}>
            Los datos que se muestran precargados en este formulario son extraídos directamente desde SIA-RH.
          </p>
        </>
      )}
    </>
  );
}

export default TablaCargaHoraria;
